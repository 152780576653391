import React, {useState, useEffect} from 'react';
import styles from "./Sections.module.css";
import Rocket from '../../images/Sierra_det.png';
import PCB from '../../images/pcb.png';
import code from '../../images/pc2.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

const Sections = () => {

  return (
    <div className={styles.page} id="sections">
        <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
            clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            style={{
                "--swiper-pagination-color": "#fff",
                "--swiper-navigation-color": "#fff",
                "--swiper-navigation-size": "2rem",
                "--swiper-pagination-bullet-inactive-color": "#fff",
            }}
        >
            <SwiperSlide>
                <div className={styles.container}>
                    <a id="about" style={{fontWeight: 400}}>Skywalkers</a><br />
                    <a className={styles.subText} style={{fontWeight: 100}}>Elktronikai Részleg</a> 
                    <img src={PCB} alt="/" className={styles.showcase}/>
                    <a className={styles.main}>
                        <a className={styles.spacer}>
                            <br /><br />
                        </a>
                        Elektronikai részlegünk több különböző feladatot lát el. Elsősorban a rakéta vezérlését tervezi és építi, másrészt az indító elektronika adó és vevő áramköreit fejleszti. A tervezési folyamat igazi kihívást jelent a csapatnak, aminek a végén kézben tarthat egy kész prototípust, melyet a programozók életre kelthetnek. A rakéta testében hasznos teher is helyet kap, amelynek fejlesztésében, vagy más kutatócsapatok támogatásában is részt vehetnek a tagjaink. Ezen projektek által az elméleti tudást át lehet vinni a gyakorlatba, ami a legnagyobb motivációs faktor lehet a hallgatóknál.
                    </a>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className={styles.container}>
                    <a id="about" style={{fontWeight: 400}}>Skywalkers</a><br />
                    <a className={styles.subText} style={{fontWeight: 100}}>Mechanikai Részleg</a> 
                    <img src={Rocket} className={styles.showcase}/>
                    <a className={styles.main}>
                        <a className={styles.spacer}>
                            <br /><br /><br /><br />
                        </a>
                        Mechanikai részlegünk felelős a rakéták testének tervezéséért és alkatrészeinek gyártásáért.
                    </a>
                </div>
            </SwiperSlide>            
            <SwiperSlide>
                <div className={styles.container}>
                    <a id="about" style={{fontWeight: 400}}>Skywalkers</a><br />
                    <a className={styles.subText} style={{fontWeight: 100}}>Szoftver Részleg</a> 
                    <img src={code} alt="/" className={styles.showcase}/>
                    <a className={styles.main}>
                        A szoftverrészlegünk felelős a rakéta működését vezérlő repülési szoftverek fejlesztéséért, valamint a hasznos teher érzékelőivel való kommunikációért és az azokból származó adatok rögzítéséért.
                    </a>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
  );
}

export default Sections;
