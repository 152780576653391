import React, {useEffect, useState} from 'react';
import styles from "./TopSection.module.css";
import { FaXmark, FaBars, FaChevronDown } from "react-icons/fa6";
import Starfield from 'react-starfield';
import Snowfall from 'react-snowfall'
import Logo from '../../images/sky2.png';

const Navbar = () => {
  const [nav, setNav] = useState(false)

  useEffect(()=>{
    document.title = 'SZTE Skywalkers';
  }, [])

  function handleSubmit() {
    setNav(!nav)
    if (nav) {
      document.body.style.overflow = 'scroll';
    } else {
      document.body.style.overflow = 'hidden';
    } 
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const scrollToAbout = () => {
    document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' });
    setNav(false)
    document.body.style.overflow = 'scroll';
  };

  const scrollToSierra = () => {
    document.getElementById('sierra')?.scrollIntoView({ behavior: 'smooth' });
    setNav(false)
    document.body.style.overflow = 'scroll';
  };

  const scrollToSections = () => {
    document.getElementById('sections')?.scrollIntoView({ behavior: 'smooth' });
    setNav(false)
    document.body.style.overflow = 'scroll';
  };

  const scrollToSponsors = () => {
    document.getElementById('sponsor')?.scrollIntoView({ behavior: 'smooth' });
    setNav(false)
    document.body.style.overflow = 'scroll';
  };

  const scrollToContact = () => {
    document.getElementById('contacts')?.scrollIntoView({ behavior: 'smooth' });
    setNav(false)
    document.body.style.overflow = 'scroll';
  };

  return (
    <header className={styles.navbar}>
        <nav>
          <ul className={nav ? [styles.menu, styles.active].join(' ') : [styles.menu]}>
            <li>
              <a onClick={() => {handleSubmit(); scrollToAbout()}}>Rólunk</a>
            </li>
            <li>
              <a onClick={() => {handleSubmit(); scrollToSierra()}}>Projektek</a>
            </li>
            <li>
              <a onClick={() => {handleSubmit(); scrollToSections()}}>Részlegek</a>
            </li>
            <li>
              <a onClick={() => {handleSubmit(); scrollToSponsors()}}>Támogatóink</a>
            </li>
            <li>
              <a onClick={() => {handleSubmit(); scrollToContact()}}>Kapcsolat</a>
            </li>
            <li>
              <a href="/join" className={styles.join}>Tagfelvétel</a>
            </li>
          </ul>
        </nav>
        <div onClick={() => {handleSubmit(); scrollToTop()}} className={styles.mobile_btn}>
          {nav ? <a><FaXmark style={{color:"#111216"}}/></a> : <a><FaBars /></a> }
        </div>
        <div className={nav ? [styles.hero, styles.blur].join(' ') : [styles.hero]}>
         <Starfield
                  starCount={8000}
                  starColor={[255, 255, 255]}
                  speedFactor={0.025}
                  backgroundColor="#111216"
          />
          {/*
          <div style={{ height: '100%', width: '100vw', background: 'transparent', position: 'fixed', top: '0vh', zIndex: -2 }}>
            <Snowfall
              color="#fefefe"
              style={{ background: '#000000' }}
              snowflakeCount={500}
              speed={[0.01, 0.01]}
              radius={[0.1, 0.25]}
              wind={[0, 0.01]}
            />
          </div>
          */}
          {          
              <div className={styles.name}>
                  <img src={Logo} alt="/" className={styles.logo}/>
                  <h1 style={{fontWeight: 400}}>SZTE</h1>
                  <h1 style={{fontWeight: 100}}>Skywalkers</h1>                           
              </div>          
          }
          <a className={styles.down}><FaChevronDown /></a> 
        </div>
    </header>
  );
}

export default Navbar;
