import React, {useState, useEffect} from 'react';
import styles from "./Sierra.module.css";
import Logo from '../../images/sky2.png';
import Rocket from '../../images/Sierra_det.png';
import Fade from 'react-reveal/Fade';

const Sierra = () => {

  return (
    <Fade>
        <img src={Logo} style={{zIndex: -1, display: "none"}}/>
        <div className={styles.container} id="sierra">
            <a style={{fontWeight: 100}}>Project Sierra</a><br />
            <a className={styles.subText} style={{fontWeight: 400}}>Áttekintés</a> 
            <img src={Rocket} alt="/" className={styles.rocket}/>
        </div>
        <div className={styles.info}>
                <div className={styles.infotab}><a className={styles.variable}>Magasság</a><a className={styles.value}>2000mm</a></div>
                <div className={styles.infotab}><a className={styles.variable}>Átmérő</a><a className={styles.value}>75mm</a></div>
                <div className={styles.infotab}><a className={styles.variable}>Tömeg</a><a className={styles.value}>2.2kg</a></div>
                <div className={styles.infotab}><a className={styles.variable}>Meghajtás</a><a className={styles.value}>PRO54 Motor</a></div>
                <div className={styles.infotab} id={styles.photo}><a className={styles.variable}>Hasznos teher</a><a className={styles.value}>Fotoakusztikus Mérőműszer</a></div>
        </div>
    </Fade>
  );
}

export default Sierra;
