import React, {useState, useEffect} from 'react';
import styles from "./AboutUs.module.css";
import Logo from '../../images/sky2.png';
import stock1 from '../../images/stock1.jpeg';
import stock2 from '../../images/stock2.jpeg';
import stock3 from '../../images/stock3.jpeg';
import stock4 from '../../images/stock4.jpeg';
import Fade from 'react-reveal/Fade';
const images = [stock1, stock2, stock3, stock4];

var count = 0;

function ImageSwapper() {

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        if (count >= 3) {
            count=0;
          } else {
            count++;
          }
      }, 5000);
      return () => clearInterval(interval);
    }, []);
    return (        
        <div style={{backgroundImage: `url(`+images[count]+`)`}} className={styles.vitrin}></div>
    );         
}

const AboutUs = () => {

  return (
    <Fade>
        <img src={images[0]} style={{zIndex: -1, display: "none"}}/>
        <img src={images[1]} style={{zIndex: -1, display: "none"}}/>
        <img src={images[2]} style={{zIndex: -1, display: "none"}}/>
        <img src={images[3]} style={{zIndex: -1, display: "none"}}/>
        <div className={styles.container}>
            <div style={{backgroundImage: `url(`+images[2]+`)`}} className={styles.vitrin}></div>
            <img src={Logo} alt="/" id="about" className={styles.logo}/>
            <a style={{fontWeight: 400}}>Skywalkers</a><br />
            <a className={styles.subText} style={{fontWeight: 100}}>Kik is vagyunk?</a> 
            <a className={styles.main}>
                {/*Our team comprising of about 30 students from University of Szeged was newly formed in 2022. Setting our first goal to be the winner of 2024's EuRoC championship we are well aware that to accomplish it one cannot simply rely on beginners' luck, so it is with great enthusiasm and determination we are setting out into our next year of model rocket development eagerly awaiting the challenges we will surely encounter along the road!*/}
                A Szegedi Tudományegyetem mintegy 30 hallgatójából álló csapatunk 2022-ben alakult. Első célként kitűztük, hogy a 2024-es EuRoC bajnokság résztvevői legyünk. Tisztában vagyunk vele, hogy ennek eléréséhez nem lehet csak a kezdők szerencséjére hagyatkozni, így nagy lelkesedéssel és elszántsággal vágunk neki a következő modellrakéta-fejlesztési évünknek, izgatottan várva a kihívásokat, amelyekkel találkozunk majd az út során!
                <br /><br />
                {/*Our varied team has members from several faculties of our University and we believe it is exactly this diversity of skills which will be of benefit to us during our developments in upcoming years.*/}
                Csapatunk tagjai egyetemünk több karáról is érkeztek, és úgy gondoljuk, hogy éppen ez a sokszínűség az, ami a következő évek fejlesztései során a hasznunkra válik.
                <br /><br />
                {/*Follow us on our adventurous journey from the first launch to the starry sky!*/}
                Kövess minket kalandos utazásunkon az első kilövéstől a csillagos égig!
            </a>
        </div>
    </Fade>
  );
}

export default AboutUs;
