import React, {useState} from 'react';
import styles from "./Sponsors.module.css";
import csiha from '../../images/csiha.png';
import mk from '../../images/mk.png';
import Snowfall from 'react-snowfall';
import Starfield from 'react-starfield';

const Sponsors = () => {
  return (
    <div id="sponsor" className={styles.container}>
      <div className={styles.ho}>

      </div>
      <a className={styles.label}>Szponzorok</a>
      <div className={styles.logos}>    
          <a href="https://www.csihazrt.hu/" target="_blank"><img src={csiha} alt="/" className={styles.spon}/></a>
          <a href="https://mk.u-szeged.hu/" target="_blank"><img src={mk} alt="/" className={styles.spon}/></a>            
      </div>   
      <a className={styles.support}>A kutatási célú rakéták gyártása nagyon drága. A versenyek által támasztott elvárásoknak való megfelelés érdekében kiváló minőségű anyagokat és eszközöket használunk. Támogass minket és érjük el együtt céljainkat.</a> 
    </div>
  );
}

export default Sponsors;
